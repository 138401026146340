import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import './index.scss';

import App from './components/App/App';
import store from './store';
import history from './store/history';
import loadExternalScript from './utils/loadExternalScript.js';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

(function () {
  String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
  };
  if (process.env.REACT_APP_GA_KEY) {
    loadExternalScript({ src: `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_KEY}`, id: 'google-analytics' }).then(() => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', process.env.REACT_APP_GA_KEY);
    });
  }
})();

const theme = createTheme({
  palette: {
    primary: {
      light: 'rgba(30, 161, 127, 0.06)',
      main: 'rgb(30, 161, 127)',
      dark: 'rgb(23, 109, 86)',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

ReactDOM.render(
  <>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <ReactNotifications />
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  </>,

  document.getElementById('root')
);
